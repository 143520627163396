import { KeycloakService, KeycloakBearerInterceptor } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        clientId: environment.keycloak.client_id,
        realm: environment.keycloak.realmName,
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    });
}

export const KeycloakInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService],
};

export const KeycloakBearerInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: KeycloakBearerInterceptor,
  multi: true,
};
