<router-outlet></router-outlet>
<p-toast
  [showTransformOptions]="'translateY(-100%)'"
  [showTransitionOptions]="'500ms'"
  [hideTransitionOptions]="'500ms'"
  [hideTransformOptions]="'translateY(100%)'"
  position="top-center"
  [preventOpenDuplicates]="true"
  [autoZIndex]="true"
>
  <ng-template let-message pTemplate="message">
    <div
      class="d-flex align-items-center justify-content-between"
      style="flex: 1"
    >
      <div class="d-flex align-items-center grow">
        <img height="20" [src]="toastImg[message.severity]" alt="" />
        <h4 class="black font_sm_normal ms-2">{{ message.summary }}</h4>
      </div>

      <button
        (click)="onClose()"
        type="button"
        class="button-white w-auto px-4"
      >
        Dismiss
      </button>
      <div
        [style]="{
          transition: 'all 1s linear',
          background: toastColor[message.severity]
        }"
        class="toast-slider position-absolute"
      ></div>
    </div>
  </ng-template>
</p-toast>
