import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private messageService = inject(MessageService);
  toastImg: any = {
    success: '/assets/images/toast-success.svg',
    error: '/assets/images/exclamation_mark.svg',
  };

  toastColor: any = {
    error: '#f04438',
    success: '#3FB12C',
  };

  onClose() {
    this.messageService.clear();
  }
}
