import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.service';
const APP_ROUTES: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/main/main.routes').then((r) => r.MAIN_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/app',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
